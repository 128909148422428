import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus, TrashSimple } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useFieldArray, useForm } from "react-hook-form";

import { partnersQueryKeys, useCourses } from "@/hooks";
import { relatedResourcesSchema } from "@/types";
import type { AddEditCourse, AddEditRelatedResources, Course } from "@/types";
import { Button, errorToast, Input, Label, Loader, useToastStore } from "@/ui";
import {
  convertMinutesToTimeFormat,
  convertTimeToMinutesFormat,
} from "@/utils/dates";
import { FormCard } from "./FormCard";

interface RelatedResourcesProps {
  partnerId: string;
  initialCourse: Course;
}

export const RelatedResources = ({
  partnerId,
  initialCourse,
}: RelatedResourcesProps) => {
  const { useUpdateCourse } = useCourses();
  const { mutateAsync: updateCourse } = useUpdateCourse();
  const pushToast = useToastStore((state) => state.pushToast);

  const defaultInitialValues = {
    relatedResources: initialCourse.relatedResources.map((resource) => ({
      ...resource,
      duration: convertTimeToMinutesFormat(resource.duration).toString() ?? "0",
    })),
  };

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    control,
    reset,
  } = useForm<AddEditRelatedResources>({
    resolver: zodResolver(relatedResourcesSchema),
    mode: "all",
    defaultValues: defaultInitialValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "relatedResources",
  });

  const emptyResource = {
    name: "",
    duration: "",
    url: "",
  };

  const queryClient = useQueryClient();

  const invalidate = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.useSelfPacedCourse(partnerId),
      exact: false,
    });
    void pushToast({
      type: "success",
      title: t("course.updated_successfully_title"),
      message: t("course.updated_successfully_message"),
    });
  };

  const onClear = async () => {
    try {
      reset({
        relatedResources: [],
      });

      const updatedCourse: AddEditCourse = {
        ...initialCourse,
        additional_resources: initialCourse.additionalResources,
        related_resources: [],
      };

      await updateCourse({
        courseId: initialCourse.id,
        course: updatedCourse,
      });

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  const onSubmit = async (data: AddEditRelatedResources) => {
    try {
      const updatedRelatedResources = data.relatedResources.map((resource) => ({
        ...resource,
        duration: convertMinutesToTimeFormat(Number(resource.duration)),
      }));

      const updatedCourse: AddEditCourse = {
        ...initialCourse,
        additional_resources: initialCourse.additionalResources,
        related_resources: updatedRelatedResources,
      };

      await updateCourse({
        courseId: initialCourse.id,
        course: updatedCourse,
      });

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  useEffect(() => {
    reset(defaultInitialValues);
  }, [initialCourse]);

  if (!initialCourse) {
    return <Loader />;
  }

  return (
    <FormCard
      title={t("course.related_resources.related_resources")}
      label={t("course.section_optional")}
      onClear={onClear}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonText={t("course.save_this_block")}
      isDirty={isDirty}
      containerClassName="bg-white border border-neutral-400"
    >
      <form className="flex flex-col gap-y-4">
        {fields.map((item, index) => (
          <div
            key={item.id}
            className="flex flex-col gap-y-4 rounded-2xl bg-neutral-50 p-4"
          >
            <div className="flex w-full justify-between">
              <Label
                className="text-lg font-semibold text-primary-950"
                label={`${t("course.related_resources.resource")} ${index + 1}`}
              />
              {fields.length > 1 && (
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="rounded-full border border-error-600 p-1.5"
                >
                  <TrashSimple size={16} className="text-error-600" />
                </button>
              )}
            </div>
            <Input
              id={`name-${index}`}
              label={t("course.related_resources.title")}
              placeholder={t("course.related_resources.title")}
              {...register(`relatedResources.${index}.name`)}
              error={errors.relatedResources?.[index]?.name?.message}
              required
            />
            <Input
              id={`duration-${index}`}
              type="number"
              label={t("course.related_resources.watching_time")}
              placeholder={t("course.related_resources.in_minutes")}
              {...register(`relatedResources.${index}.duration`)}
              error={errors.relatedResources?.[index]?.duration?.message}
              required
              min="0"
            />
            <Input
              id={`url-${index}`}
              label={t("course.related_resources.link")}
              placeholder={t("course.related_resources.paste_a_url_here")}
              {...register(`relatedResources.${index}.url`)}
              error={errors.relatedResources?.[index]?.url?.message}
              required
            />
          </div>
        ))}
        {fields.length < 3 && (
          <div className="flex w-full justify-center">
            <Button
              variant="outlined"
              className="w-fit"
              onClick={() => append(emptyResource)}
            >
              <Plus /> {t("course.related_resources.add_related_resource")}
            </Button>
          </div>
        )}
      </form>
    </FormCard>
  );
};
