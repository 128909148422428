import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Lock } from "@phosphor-icons/react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

import { useResetPassword } from "@/api";
import i18n from "@/i18n";
import type { errorMessageKeys } from "@/types";
import { Alert, Button, Circle, Heading } from "@/ui";
import { PasswordInput } from "@/ui/form/PasswordInput";
import { ROUTES } from "../../router/routes";

const passwordFormSchema = z
  .object({
    password: z
      .string()
      .min(8, {
        message: i18n.t("auth.password_should_have_at_least_8_characters"),
      })
      .regex(/[A-Z]/, {
        message: i18n.t(
          "auth.password_should_have_at_least_one_uppercase_letter",
        ),
      })
      .regex(/[a-z]/, {
        message: i18n.t(
          "auth.password_should_have_at_least_one_lowercase_letter",
        ),
      })
      .regex(/\d/, {
        message: i18n.t("auth.password_should_have_at_least_one_number"),
      })
      .regex(/[@$!%*?&]/, {
        message: i18n.t(
          "auth.password_should_have_at_least_one_special_character",
        ),
      }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: i18n.t("auth.passwords_must_match"),
    path: ["confirmPassword"],
  });

type PasswordForm = z.infer<typeof passwordFormSchema>;

interface PasswordFormProps {
  heading: string;
  isReset?: boolean;
}

export const PasswordForm = ({
  heading,
  isReset = true,
}: PasswordFormProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { mutate: resetPassword, error: resetError } = useResetPassword();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  useEffect(() => {
    if (!token || !email) {
      navigate(ROUTES.notFound);
    }
  }, [token, email, navigate]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordForm>({
    resolver: zodResolver(passwordFormSchema),
  });

  const onSubmit: SubmitHandler<PasswordForm> = (data) => {
    if (token && email) {
      const resetPasswordData = {
        token,
        email,
        password: data.password,
        password_confirmation: data.confirmPassword,
      };
      resetPassword(resetPasswordData, {
        onSuccess: () => {
          if (isReset) {
            navigate(ROUTES.passwordResetSuccess);
          } else {
            navigate(ROUTES.passwordCreatedSuccess);
          }
        },
      });
    } else {
      navigate(ROUTES.notFound);
    }
  };

  const errorMessageKey = (
    resetError?.error.code ? `auth.${resetError?.error.code}` : `error.general`
  ) as errorMessageKeys;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex min-h-screen flex-col justify-between gap-y-14 py-9 md:min-h-0 md:px-24 md:py-14"
    >
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          <Heading
            heading={heading}
            left={
              <Circle>
                <Lock size={24} className="text-tertiary-800" />
              </Circle>
            }
          />
          <div className="flex flex-col gap-y-2 rounded-lg bg-tertiary-100 p-4 font-semibold text-primary-950">
            <span>{t("auth.password_must_have_at_least")}</span>
            <ul className="font-regular list-outside list-disc space-y-2.5 pl-4 leading-none text-primary-950">
              <li>{t("auth.8_characters")}</li>
              <li>{t("auth.one_number")}</li>
              <li>{t("auth.one_uppercase_letter")}</li>
              <li>{t("auth.one_lowercase_letter")}</li>
              <li>{t("auth.one_special_character")}</li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-y-8">
          <PasswordInput
            id="password"
            label={t("auth.new_password")}
            required
            error={errors.password?.message}
            {...register("password")}
          />
          <PasswordInput
            id="confirmPassword"
            label={t("auth.repeat_password")}
            required
            error={errors.confirmPassword?.message}
            {...register("confirmPassword")}
          />
          <Alert variant="error" hidden={!resetError}>
            {t(errorMessageKey)}
          </Alert>
        </div>
      </div>
      <Button size="md" variant="filled" type="submit" className="md:w-44">
        {t("general.continue")}
      </Button>
    </form>
  );
};
