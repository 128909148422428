import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus, TrashSimple } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useFieldArray, useForm } from "react-hook-form";

import { partnersQueryKeys, useCourses } from "@/hooks";
import { additionalResourcesSchema } from "@/types";
import type {
  AddEditAdditionalResources,
  AddEditCourse,
  Course,
} from "@/types";
import {
  Button,
  errorToast,
  Input,
  Label,
  Loader,
  Select,
  TextArea,
  useToastStore,
} from "@/ui";
import {
  convertResourceTypeFromNull,
  getAdditionalInformation,
  getAdditionalInformationTagOptions,
  getNonAdditionalInformation,
} from "@/utils/courseUtils";
import { FormCard } from "./FormCard";

interface AdditionalInformationProps {
  partnerId: string;
  initialCourse: Course;
}

export const AdditionalInformation = ({
  partnerId,
  initialCourse,
}: AdditionalInformationProps) => {
  const { useUpdateCourse } = useCourses();
  const { mutateAsync: updateCourse } = useUpdateCourse();
  const pushToast = useToastStore((state) => state.pushToast);
  const nonAdditionalInformationData = getNonAdditionalInformation(
    initialCourse.additionalResources,
  );
  const additionalInformationData = getAdditionalInformation(
    initialCourse.additionalResources,
  );
  const additionalInformationTagOptions = getAdditionalInformationTagOptions();

  const defaultInitialValues = {
    additionalResources: additionalInformationData,
  };

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    control,
    reset,
  } = useForm<AddEditAdditionalResources>({
    resolver: zodResolver(additionalResourcesSchema),
    mode: "all",
    defaultValues: defaultInitialValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "additionalResources",
  });

  const emptyResource = {
    description: "",
    action: "",
    url: "",
    type: null,
    title: "",
  };

  const queryClient = useQueryClient();

  const invalidate = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.useSelfPacedCourse(partnerId),
      exact: false,
    });
    void pushToast({
      type: "success",
      title: t("course.updated_successfully_title"),
      message: t("course.updated_successfully_message"),
    });
  };

  const onClear = async () => {
    try {
      reset({
        additionalResources: [],
      });

      const updatedCourse: AddEditCourse = {
        ...initialCourse,
        additional_resources: [],
        related_resources: initialCourse.relatedResources,
      };

      await updateCourse({
        courseId: initialCourse.id,
        course: updatedCourse,
      });

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  const onSubmit = async (data: AddEditAdditionalResources) => {
    try {
      const formattedAdditionalResources = convertResourceTypeFromNull(
        data.additionalResources,
      );

      const mergedResources = [
        ...nonAdditionalInformationData,
        ...formattedAdditionalResources,
      ];

      const updatedCourse: AddEditCourse = {
        ...initialCourse,
        additional_resources: mergedResources,
        related_resources: initialCourse.relatedResources,
      };

      await updateCourse({
        courseId: initialCourse.id,
        course: updatedCourse,
      });

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  useEffect(() => {
    reset(defaultInitialValues);
  }, [initialCourse]);

  if (!initialCourse) {
    return <Loader />;
  }

  return (
    <FormCard
      title={t("course.additional_information")}
      label={t("course.section_optional")}
      onClear={onClear}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonText={t("course.save_this_block")}
      isDirty={isDirty}
      containerClassName="bg-white border border-neutral-400"
    >
      <form className="flex flex-col gap-y-4">
        {fields.map((item, index) => (
          <div
            key={item.id}
            className="flex flex-col gap-y-4 rounded-2xl bg-neutral-50 p-4"
          >
            <div className="flex w-full justify-between">
              <Label
                className="text-lg font-semibold text-primary-950"
                label={`${t("course.information_card")} ${index + 1}`}
              />
              {fields.length > 1 && (
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="rounded-full border border-error-600 p-1.5"
                >
                  <TrashSimple size={16} className="text-error-600" />
                </button>
              )}
            </div>
            <Select
              options={additionalInformationTagOptions}
              id={`type-${index}`}
              label={t("course.tag")}
              placeholder={t("course.choose_a_tag")}
              {...register(`additionalResources.${index}.type`)}
              required
            />
            <Input
              id={`title-${index}`}
              label={t("course.title")}
              placeholder={t("course.title")}
              {...register(`additionalResources.${index}.title`)}
              error={errors.additionalResources?.[index]?.title?.message}
              required
            />
            <TextArea
              id={`description-${index}`}
              label={t("course.description")}
              placeholder={t("course.add_a_description")}
              {...register(`additionalResources.${index}.description`)}
              error={errors.additionalResources?.[index]?.description?.message}
              required
            />
            <div className="border-t border-neutral-400"></div>
            <div className="w-full">
              <Label
                className="text-lg font-semibold text-primary-950"
                label={t("course.button")}
              />
            </div>
            <Input
              id={`action-${index}`}
              label={t("course.text")}
              placeholder={t("course.button_text")}
              {...register(`additionalResources.${index}.action`)}
              error={errors.additionalResources?.[index]?.action?.message}
              required
            />
            <Input
              id={`url-${index}`}
              label={t("course.link")}
              placeholder={t("course.button_link")}
              {...register(`additionalResources.${index}.url`)}
              error={errors.additionalResources?.[index]?.url?.message}
              required
            />
          </div>
        ))}
        <div className="flex w-full justify-center">
          <Button
            variant="outlined"
            className="w-fit"
            onClick={() => append(emptyResource)}
          >
            <Plus /> {t("course.add_resource")}
          </Button>
        </div>
      </form>
    </FormCard>
  );
};
